import React from 'react'

const Aboutus = () => {
    return (
        <div style={{marginTop:30}}>

            <section class="section service" id="service" aria-label="service">
                <div class="container">

                    <h2 class="h2 section-title">About Us</h2>



                    <section class="section hero" id="home" aria-label="hero">
                        <div class="container">

                        <figure class="hero-banner">
                                <img src="https://i.ibb.co/S6Jw3Tg/Image-b.png" width="720" height="673" alt="hero banner" class="w-100" />
                            </figure>

                            <div class="hero-content">

                                

                                <h1 class="h1 hero-title">About GwalaxMedia</h1>

                                <p class="hero-text">
                                Digital advertising is a key to every advertisers media plan and a chunk of spends goes into performance marketing.We are there to help them with varied performance marketing solutions Our team of professionals and industry experts understands the value of teamwork. By ‘we’, we mean our publishers who, along with our seasoned team of affiliate managers, always remain at the heart of the entire advertising process, from curating a cost-effective marketing campaign to remolding it for the purpose of accomplishing the desired objectives.
                                </p>

                              

                            </div>

                            

                        </div>
                    </section>
                </div>
            </section>
        </div>
    )
}

export default Aboutus