import React from 'react'

const Footer = () => {
  return (
    <footer class="footer" style={{backgroundColor:'black'}}>
    <div class="">
        <div class="row">
            <div class="footer-col">
                <img style={{margin:'auto',marginTop:10,marginBottom:10}} width='150' height='35' src='../assets/img/gwalaxmedia-logo.png'/>
                <p style={{color:'white'}}>
                GwalaxMedia Based on Digital Marketing Services and we are working with 100+ Company Partnership and We are Planning to More Partnership With More Company
                </p>
            </div>
            <div class="footer-col">
                <h4>Business Hours</h4>
                <div>
                     <p style={{color:'white',fontWeight:'bold',fontSize:15}}>Opining Days:</p>
                     <p style={{color:'white',fontSize:14}}>Monday - Friday :9AM to 6PM</p>
                     <p style={{color:'white',fontSize:14}}>Saturday : 9AM to 5PM</p>
                </div>
                <div>
                     <p style={{color:'white',fontWeight:'bold',fontSize:15}}>Vacations:</p>
                     <p style={{color:'white',fontSize:14}}>All Sunday Days</p>
                     <p style={{color:'white',fontSize:14}}>All Official Holidays</p>
                </div>
            </div>
            <div class="footer-col">
                <h4>Important Links</h4>
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-&-conditions">Terms & Conditions</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4>follow us</h4>
                <div class="social-links">
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-instagram"></i></a>
                    <a target='_blank' href="https://www.linkedin.com/company/gwalaxmedia"><i class="fab fa-linkedin-in"></i></a>
                </div>
            </div>
        </div>

       
        <p style={{color:'white',marginTop:30}} class="copyright">
        &copy; <a href="/" class="copyright-link">GwalaxMedia </a> 2025 All Rights Reserved by 
      </p>
    </div>
</footer>
  )
}

export default Footer