import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react'
import Slider from "react-slick";

const Clients = () => {

    
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, marginRight:20, display: "block", backgroundColor:'#ff4f48',color:'#ff4f48',borderRadius:10,zIndex:5 }}
            onClick={onClick}
            />
            );
        }
        
        function SamplePrevArrow(props) {
            const { className, style, onClick } = props;
            return (
                <div
                className={className}
                style={{ ...style, marginLeft:20, display: "block",backgroundColor:'#ff4f48',color:'#ff4f48',borderRadius:10,zIndex:5 }}
                onClick={onClick}
                />
                );
            }



            var settings = {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 3,
                initialSlide: 0,
                nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    }
                ]
            }


            return (
                <section style={{marginTop:30}} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h2 section-title">Our Clients</h2>

                <p class="section-text">

                </p>

                
                    <Slider {...settings}>
                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/mHCVG8R/casumo-logo-transparent.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/GPW8Ndc/th-q-rush-by-hike-pro-apk-download.jpg" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/1d4bDqd/1689270035-3662.jpg" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/wN9wPgZ/1200x630wa.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/F8fCtG8/hdfc-sky.webp" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/K0SDLkq/leovegas.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/n0yxG2d/images-q-tbn-ANd9-Gc-Q33x-EI8-VEfs-Ln-YAU8c-WIK3eap-XD3t7e-BHEJw-s.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://i.ibb.co/4d5Ljz0/can-you-use-paytm-wallet-after-february-29-heres-what-all-paytm-users-need-to-know.jpg" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120,marginLeft:10 }} src="https://i.ibb.co/yYqmsRn/groww-mutual-fund-issue-what-investors-can-do-to-safeguard-their-investments.jpg" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120,marginLeft:10 }} src="https://i.ibb.co/8Bg9StH/clogvv27a034uwzoavav3omzt-featured-img-copy2-full.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120,marginLeft:10 }} src="https://i.ibb.co/QN2KktY/640px-Airtel-payments-bank-logo.jpg" />
                        </div>



                    </Slider>
                </div>

           
        </section>
    )
}

export default Clients